
/* Sticky footer styles
    -------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}